<template>
  <div id="famous">
    <Else></Else>
    <div class="famous-box">
        <div v-for="(v,k) in arr" :key="k">
            <div class="famous-img">
                <img :src="v.trademarkUrl" alt="">
            </div>
            <div class="famous-info">
                <div class="info1">
                    <div></div>
                    <div>{{v.trademarkName}}</div>
                </div>
                <div>
                    <span>注册号：</span>
                    <span>{{v.registerNum}}</span>
                </div>
                <div>
                    <span>分类：</span>
                    <span>{{v.cateName}}</span>
                </div>
                <div>
                    <span>企业：</span>
                    <span>{{v.businessName}}</span>
                </div>
            </div>
            <div class="famous-intr">
                <div class="intr1">故事简介：</div>
                <div class="intr2">{{v.brandStory || '暂无'}}</div>
            </div>
            <div v-show="v.brandStory" class="famous-thing" @click="detail(v.id)">品牌故事</div>
        </div>
    </div>
  </div>
</template>

<script>
import {famous} from "../../api/api";
import Else from '../ui/else.vue';
export default {
  name: 'famous',
  data(){
      return{
        arr:[]
      }
  },
  components: {
      Else
  },
  computed:{
      
  },
  methods:{
    async getData(){
              let res = await famous()
              if(res.data.code === 200){
                this.arr = res.data.data;
                 for(var i = 0;i<this.arr.length;i++){
                  if(this.arr[i].brandStory){
                    this.arr[i].brandStory= this.arr[i].brandStory.split("\\n").join()
                  }
                }
                // console.log(this.arr)
                //子向父发送数据
                this.$nextTick(()=>{
                    var height =  document.documentElement.scrollHeight;
                    console.log(height)
                    window.parent.postMessage(
                    {
                        data: height,
                    },
                    "*"
                    );
                })
              }
          },
          detail(val){
            this.$router.push({
                path:'/brandThing',
                query:{
                    id:val
                }
            })
          },
         
  },
  mounted(){
      this.getData()
  }
}

</script>

<style scoped>
#famous{
    padding: 80px 0 45px;
}
.famous-box{
    width: 1200px;
    margin: 0 auto;
}
.famous-box>div{
    width: 1200px;
    height: 220px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(85,84,91,0.2700);
    border-radius: 4px;
    margin-bottom: 14px;
}

.famous-box>div>div{
    float: left;
}
.famous-box>div>div>img{
   width:140px;
}
.famous-img{
    height: 100%;
    margin: 0 60px 0 43px;
    display: flex;
    align-items: center;
    text-align:center;
}
.famous-info{
    width: 290px;
    margin-top: 40px;
}
.famous-info>div{
    margin-bottom: 8px;
}
.famous-info>div>span:nth-of-type(1),.intr1{
    font-size: 16px;
    font-weight: 600;
    color: #3F3F3F;
}
.famous-info>div>span:nth-of-type(2){
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #626262;
}
.info1{
    height: 33px;
     margin-bottom: 15px!important;
}
.info1>div{
    float: left;
}
.info1>div:nth-of-type(1){
    width: 3px;
    height: 22px;
    background: #2553B3;
    margin-right: 6px;
    margin-top: 6px;
   
}
.info1>div:nth-of-type(2){
    font-size: 24px;
}
.famous-intr{
    width: 300px;
    height: 120px;
    margin: 85px 130px 0 0;
}
.famous-intr>div{
    float: left;
}
.intr2{
    width: 220px;
    height: 80px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #626262;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.famous-thing{
    margin-top: 85px;
    cursor: pointer;
    width: 90px;
    height: 27px;
    line-height: 27px;
    background: #2554B3;
    border-radius: 6px;
    font-size: 14px;
   text-align: center;
    color: #FFFFFF;
}
</style>
